body {
  color: lightgrey;
}

.logo {
  width: 150px;
  height: 75px;
}

.NavBar {
  background-color: #171717;
}

.navLink {
  margin: 0 10px;
  color: lightgrey;
}

.navLink:hover {
  color: #ff9900;
  text-decoration: none;
}

.tickerContent {
  margin: auto 10px;
  display: inline-block;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 992px) {
  .NavBar a {
    margin: 10px 20px 10px 20px;
  }

  .tickerContent {
    visibility: hidden;
    display: none;
  }
}
