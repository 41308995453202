.service {
  width: 30%;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
}

.serviceSummary {
  margin: auto;
  padding: 10px;
  width: 50%;
  color: lightgrey;
  letter-spacing: 1px;
}

.image {
  width: 125px;
  height: 100px;
}

.service h4 {
  padding: 20px;
  color: #ff9900;
}

.service li {
  margin-top: 10px;
  text-align: left;
  letter-spacing: 1px;
}

.contactButtonContainer {
  text-align: center;
}

.contactButton {
  background-color: #ff9900;
  color: black;
  border: none;
}

.contactButton:hover {
  background-color: #ff9900;
  color: black;
  border: none;
}

.contactButton:active {
  background-color: #ff9900;
  color: black;
  border: none;
}

@media (max-width: 992px) {
  .service {
    width: 100%;
    display: block;
  }

  .serviceSummary {
    width: 90%;
  }
}
