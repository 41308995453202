@media (min-width: 992px) {
  .form {
    margin: auto;
    width: 60%;
    float: left;
  }

  .contactInfo {
    width: 30%;
    float: right;
  }
}

.contactContainer {
  margin: 50px auto;
  width: 90%;
  overflow: hidden;
}

.contactInfo {
  margin: 50px 0 50px 0;
}

.input {
  background-color: #171717;
  color: lightgrey;
  border: none;
}

.input:focus {
  background-color: #171717;
  color: lightgrey;
}

.Contact a {
  color: #ff9900;
}

.hr {
  background-color: #171717;
  height: 2px;
}

.Contact .button {
  background-color: #ff9900;
  color: black;
  border: none;
}

.emailSent {
  color: #ff9900;
}

.bioContainer {
  margin: 50px auto;
  padding-top: 20px;
  width: 90%;
  overflow: hidden;
}

.bioImage {
  border-radius: 10px;
}
