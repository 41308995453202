.BtcHistoricalChart {
  width: 100%;
  height: 500px;
}

.seriesLabel {
  padding: 1vw;
  display: inline;
  cursor: pointer;
}

.spinnerContainer {
  width: 100%;
  height: 400px;
}

.spinner {
  position: relative;
  top: 50%;
  left: 50%;
}

@media (max-width: 992px) {
  .BtcHistoricalChart {
    height: 300px;
  }

  .spinnerContainer {
    height: 300px;
  }
}
