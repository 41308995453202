.homeImageContainer {
  height: Calc(100vh - 101px);
  background-image: url("../assets/homeImage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sloganContainer {
  position: relative;
  top: 40%;
  left: 5%;
  width: 30%;
}

.carousel .slide {
  background: none;
}

.slogan {
  color: white;
  text-align: left;
  letter-spacing: 2px;
}

.homeButton {
  cursor: pointer;
  color: #ff9900;
  text-align: left;
  font-size: 2.5vh;
}

.homeButton:hover {
  color: #ff9900;
  text-decoration-color: #ff9900;
}

.rowContainer {
  padding: 100px 0;
  text-align: center;
}

.headerCentered {
  text-align: center;
  margin-bottom: 50px;
}

.missionStatementContainer {
  margin: auto;
  padding: 20px;
  width: 60%;
}

.missionStatementContainer p {
  font-size: 20px;
  letter-spacing: 1px;
}

.gif {
  width: 400px;
  height: 200px;
}

@media (max-width: 992px) {
  .homeImageContainer {
    height: Calc(100vh - 121px);
  }

  .sloganContainer {
    width: 50%;
    top: 5%;
  }

  .slogan {
    font-size: 22.5px;
  }

  .rowContainer {
    padding: 50px 0;

    text-align: center;
  }

  .missionStatementContainer {
    width: 90%;
  }

  .gif {
    width: 100%;
    height: 100%;
  }
}
