.opportunityCostStatementContainer {
  width: 90%;
  margin: auto;
  padding: 20px;
  font-size: 18px;
  letter-spacing: 1px;
}

.opportunityContainer {
  margin: 100px auto 100px auto;
  width: 90%;
}

.calculator {
  width: 22.5%;
  float: left;
}

.historicalChart {
  width: 75%;
  float: right;
}

@media (max-width: 992px) {
  .calculator {
    width: 100%;
  }

  .historicalChart {
    width: 100%;
  }
}
