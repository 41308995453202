.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.router {
  flex: 1 0 auto;
}

.header {
  width: 90%;
  margin: 50px auto;
}
